<template>
  <v-container class="gradient" fluid fill-height>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="8" md="5" lg="4" xl="3">
        <v-card class="pa-6">
          <div class="px-16 mb-10">
            <v-img :src="require('@/assets/logotype.svg')" contain />
          </div>

          <v-form v-model="valid" ref="form">
            <div @keypress.enter.prevent="submit()" class="mb-6">
              <custom-input
                v-model="form.login"
                label="Seu usuário"
                placeholder="Digite seu usuário"
              />

              <custom-input
                v-model="form.password"
                label="Sua senha"
                placeholder="Digite sua senha"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
              />
            </div>

            <custom-bttn label="Acessar" block @click="submit()" />
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <loader-hover v-if="loading" />
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { request } from "@/services";
import { displayAlert } from "@/utils";

export default {
  data() {
    return {
      loading: false,
      valid: false,
      showPassword: false,
      form: {
        login: "",
        password: "",
      },
    };
  },

  methods: {
    ...mapActions(["setToken"]),

    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const payload = {
          method: "admLogin",
          login: this.form.login,
          pass: this.form.password,
        };

        await request(payload).then((res) => {
          this.setToken(res.authToken);
          this.$router.push({ path: "/home" });
        });
      } catch (err) {
        this.displayAlert(err.message, 1);
      } finally {
        this.loading = false;
      }
    },

    displayAlert,
  },
};
</script>

<style></style>
